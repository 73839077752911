<template>
    <div class="notice-detail">
        <van-nav-bar class="h5-bar" :title="$t('index.text1')" left-arrow fixed placeholder @click-left="onClickLeft" />
        <h3 class="pc-title">{{ announcements.title}}</h3>
        <div class="announcement-container">
            <p class="announcements-title">{{ announcements.title }}</p>
            <p class="announcements-time">{{ announcements.created_at }}</p>
            <div class="announcements-content" v-html="announcements.content"></div>
        </div>
        <Tabbar class="pc-tabbar" :key="lang" bgColor="#FFFFFF" :selectTab="0" />
    </div>
</template>

<script>
import { getAnnouncementDetail } from '@/api'
import Tabbar from "@/components/Tabbar/Tabbar.vue";
import { mapState } from "vuex";
export default {
    data() {
        return {
            announcements: {
                title: '',
                created_at: '',
                content: ''
            }
        }
    },
    components: {
        Tabbar,
    },
    computed: {
        id() {
            return this.$route.query.id
        },
        ...mapState(['lang'])
    },
    created() {
        this._getAnnouncementDetail()
    },
    methods: {
        _getAnnouncementDetail() {
            getAnnouncementDetail({ id: this.id }).then(res => {
                this.announcements = res.data
            })
        },
        onClickLeft() {
            this.$router.back()
        }
    }
}
</script>

<style lang="less" scoped>
.announcements-title {
    font-size: 16px;
}
.announcements-time {
    margin-top: 12px;
    color: #909399;
    text-align: right;
}
.announcements-content {
    background-color: #fff;
    margin-top: 24px;
    padding: 12px;
    border-radius: 6px;
}
.announcement-container {
    padding: 12px;
}
.pc-tabbar {
    display: none;
}
.pc-title {
    display: none;
}
@media only screen and (min-width: 750px) {
    .pc-tabbar {
        display: block;
    }
    .h5-bar {
        display: none;
    }
    .pc-title {
        display: block;
        font-weight: 600;
        font-size: 38px;
        color: var(--font-color);
        margin: 51px auto 18px auto;
        text-align: center;
    }
    .notice-detail {
        width: 60%;
        margin: 0 auto;
        padding: 90px;
        .announcements-title {
            font-size: 38px;
            display: none;
        }
        .announcements-time {
            margin-top: 0;
            font-size: 18px;
            color: #666666;
            text-align: left;
            border-bottom: 2px dashed #808080;
            padding-bottom: 20px;
        }
        .announcements-content {
            background: none;
            margin-top: 24px;
            padding: 0;
            border-radius: 0;
            font-size: 18px;
        }
        .announcement-container {
            padding: 40px;
            width: 100%;
            border-radius: 16px 16px 16px 16px;
            border: 1px solid #666666;
        }
    }
}
</style>
